// Large Callout

import { forwardRef } from 'react'
import type { MutableRefObject } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import { isNotEmptyArray } from 'utils/client'
import { HorizontalCard, HeaderContent } from 'ui'
import type { PageDocumentDataBodyLargeCalloutSlice } from 'types/types.generated'

const LargeCallout = forwardRef(
	(props: PageDocumentDataBodyLargeCalloutSlice, ref: MutableRefObject<HTMLDivElement>) => {
		const { primary, items, globalData } = props || {}

		const {
			section_heading,
			section_description,
			primary_cta_label,
			primary_cta_link,
			secondary_cta_label,
			secondary_cta_link,
			right_align_cards,
			background_image,
			background_color,
			text_contrast_mode
		} = primary || {}

		const bottom_align_cards = !right_align_cards

		return (
			<section
				className="large-callout section container py-12 remove-space-if-in-rich-text"
				ref={ref}
			>
				<div
					className={clsx(
						'relative rounded-lg lg:h-[680px]',
						`${text_contrast_mode === 'light' ? 'text-white' : 'text-black-rt'}`,
						'flex gap-6 justify-between p-6 sm:p-10 lg:p-20',
						{
							['max-md:flex-col  items-center']: right_align_cards,
							['flex-col']: bottom_align_cards
						}
					)}
					style={{ background: background_color }}
				>
					{background_image.url ? (
						<Image
							src={background_image.url}
							alt={background_image.alt || ''}
							fill
							className="absolute object-cover rounded-lg"
							sizes="(max-width: 768px) calc(100vw - 32px), 780px"
						/>
					) : null}

					{/* Title, description, CTAs */}
					<HeaderContent
						title={section_heading}
						description={section_description}
						primaryCta={{ label: primary_cta_label, link: primary_cta_link }}
						secondaryCta={{ label: secondary_cta_label, link: secondary_cta_link }}
						textContrast={text_contrast_mode}
						centered={false}
						className="z-20 w-full"
						globalData={globalData}
					/>

					{/* Cards - Bottom-aligned row or right-aligned column */}
					{isNotEmptyArray(items) ? (
						<div
							className={clsx('z-20 flex gap-4', {
								['flex-col  max-md:w-full']: right_align_cards,
								['flex max-lg:flex-col justify-center']: bottom_align_cards
							})}
						>
							{items.map((card, i) => {
								return i < 3 ? (
									<HorizontalCard
										key={i}
										resourceObj={{
											background_color: card.card_background_color,
											text_contrast: card.card_text_contrast_mode,
											card_image: card.card_icon,
											...card
										}}
										roundedImage={false}
										className={`w-full md:w-[430px] bg-white ${card.card_icon.url ? '' : 'p-4'}`}
									/>
								) : null
							})}
						</div>
					) : null}
				</div>
			</section>
		)
	}
)
LargeCallout.displayName = 'LargeCallout'

export default LargeCallout
